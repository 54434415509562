import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';

const StyledBackground = styled.div`
  background-image: url(${props => props.src});
  background-color: #cccccc;
  background-repeat: no-repeat;
  margin-top: ${props => (props.withHeader ? '-74px' : null)};
  background-size: cover;
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    background-size: cover;
  }

  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    background-size: cover;
    height: 100vh;
  }
`;

function BackgroundImage(props) {
  return (
    <StyledBackground withHeader={props.withHeader} src={props.src}>
      {props.children}
    </StyledBackground>
  );
}

export default BackgroundImage;
