/* All imports */
import React from 'react';
import YouTube from 'react-youtube';
import { useStaticQuery, graphql } from 'gatsby';
import design from '../config/design';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import BackgroundImage from '../components/BackgroundImage/BackgroundImage';
import Title from '../components/Title/Title';
import { Visible } from '../components/Visible/Visible';
import { View } from '../components/View/View';
import Button from '../components/Button/Button';
import Text from '../components/Text/Text';
import { Spaced } from '../components/Container/Container';
import BackgroundVideo from '../components/BackgroundVideo/BackgroundVideo';
import styled from 'styled-components';
import { BigView } from '../components/BigView/BigView';

// Limit texts in container
/* const LimitContainer = styled.div`
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    max-width: 100%;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    max-width: 90%;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    max-width: 70%;
  }
`; */

const HowItWorksImage = styled.img`
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    width: 80%;
    display: flex;
    justify-content: center;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    width: 60%;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    width: auto;
  }
`;

const GridImage = styled.div`
  display: grid;
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    grid-template-columns: auto;
    justify-content: center;
    grid-gap: 10px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    grid-template-columns: auto;
    justify-content: center;
    grid-gap: 10px;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    grid-template-columns: 400px 700px;
    justify-content: left;
    grid-gap: 30px;
  }
`;

function Home() {
  const _onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  /* CMS Data */
  const { data } = useStaticQuery(
    graphql`
      query {
        data: allDatoCmsHomepage {
          nodes {
            title
            youtubevideoid
            subtitle
            subtitlevideo
            textbutton
            textsecondarybutton
            titlevideo
            linkbutton
            linksecondarybutton
            homepagetext
            secondarytitle
            mobilebackgroundimage {
              url
            }
            howItWorksImage {
              url
            }
            videoUrl
            # backgroundVideo {
            #   url
            # }
          }
        }
      }
    `
  );

  // Get the first node in homepage
  const homepage = data.nodes[0];

  //Homepage
  return (
    <>
      <LayoutWrapper>
        <Visible desktop>
          <View
            desktopLeft={6}
            desktopRight={6}
            mobileLeft={1}
            mobileRight={1}
            tabletLeft={3}
            tabletRight={3}
            style={{
              position: 'absolute',
              zIndex: 3,
            }}
          >
            <BigView>
              <View desktopTop={12} mobileTop={8} tabletTop={8} />
              <Title big color={design.colors.white} content={homepage.title} />
              <View top={0.5} />
              <Text color={design.colors.white} content={homepage.subtitle} />
              <View top={1.5} />
              <Button
                content={homepage.textbutton}
                href={homepage.linkbutton}
                bgColor="primaryOrange"
                type="rounded"
                width="block"
              />
            </BigView>
          </View>
          <BackgroundVideo videoUrl={homepage.videoUrl} />
        </Visible>
        <Visible mobile tablet>
          <BackgroundImage withHeader src={homepage.mobilebackgroundimage.url}>
            <View
              desktopLeft={6}
              desktopRight={6}
              mobileLeft={1}
              mobileRight={1}
              tabletLeft={3}
              tabletRight={3}
              bottom={2}
            >
              <View desktopTop={12} mobileTop={8} tabletTop={8} />
              <Title big color={design.colors.white} content={homepage.title} />
              <View top={0.5} />
              <Text color={design.colors.white} content={homepage.subtitle} />
              <View top={1.5} />
              <Button
                content={homepage.textbutton}
                href={homepage.linkbutton}
                bgColor="primaryOrange"
                type="rounded"
                width="block"
              />
            </View>
          </BackgroundImage>
        </Visible>
        <BigView>
        <View
          desktopLeft={6}
          desktopRight={6}
          mobileLeft={1}
          mobileRight={1}
          tabletLeft={3}
          tabletRight={3}
        >
          <View mobileTop={2} desktopTop={5} tabletTop={5} />
          <Title color={design.colors.blue150} content={homepage.titlevideo} />
          <Text color={design.colors.dark} content={homepage.subtitlevideo} />
          <View top={2} />
          <Visible mobile tablet>
            <YouTube
              videoId={homepage.youtubevideoid}
              opts={{ height: '193', width: '100%' }}
              onReady={_onReady}
            />
          </Visible>
          <Visible desktop>
            <YouTube
              videoId={homepage.youtubevideoid}
              opts={{ height: '720', width: '100%' }}
              onReady={_onReady}
            />
          </Visible>
          <View mobileTop={2} desktopTop={5} tabletTop={5} />
          <Spaced>
            <div>
              <Title
                big
                color={design.colors.blue150}
                content={homepage.secondarytitle}
              />

              <View top={1.5} />
              <GridImage>
                <HowItWorksImage src={homepage.howItWorksImage.url} />
                <div>
                  <Text
                    color={design.colors.dark}
                    content={homepage.homepagetext}
                  />
                  <View top={2} bottom={2}>
                    <Button
                      content={homepage.textsecondarybutton}
                      href={homepage.linksecondarybutton}
                      bgColor="primaryGreen"
                      width="block"
                    />
                  </View>
                </div>
              </GridImage>
            </div>
          </Spaced>
        </View>
        </BigView>
      </LayoutWrapper>
    </>
  );
}

export default Home;
