/* All imports */
import React from 'react';
import styled from 'styled-components';

// Video Component Styles
const Video = styled.video`
  position: relative;
  width: 100%;
  margin-top: -74px;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
`;

// Component Layout
function BackgroundVideo(props) {
  return (
    <Video autoPlay loop muted>
      <source src={props.videoUrl} type="video/mp4" />
    </Video>
  );
}

export default BackgroundVideo;
