import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import { Link as StyledLink } from 'gatsby';

const StyledButton = styled.button`
  background-color: ${props =>
    props.bgColor === 'primaryOrange'
      ? `${design.colors.primaryOrange}`
      : `${design.colors.primaryGreen}`};
  &:hover {
    background-color: ${props =>
      props.bgColor === 'primaryOrange'
        ? `${design.colors.primaryOrangeHover}`
        : `${design.colors.primaryGreenHover}`};
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #868686;
  }
  color: ${design.colors.white};
  border: none;
  transition: background-color 0.3s ease-in-out;
  border-radius: ${props => (props.type === 'rounded' ? '39px' : '5px')};
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: '0.1em';

  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${props =>
      props.width === 'full'
        ? '45px'
        : props.width === 'block'
        ? '45px'
        : '55px'};
    line-height: 15px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    width: ${props =>
      props.width === 'full'
        ? '321px'
        : props.width === 'block'
        ? '304px'
        : '260px'};
    height: ${props =>
      props.width === 'full'
        ? '43px;'
        : props.width === 'block'
        ? '43px'
        : '54px'};

    font-size: ${props =>
      props.width === 'full'
        ? '16px'
        : props.width === 'block'
        ? '14px'
        : '18px'};
    line-height: 25px;
  }
`;

const Link = styled(StyledLink)`
  text-decoration: none;
`;

const Button = props => {
  return (
    <>
      <Link to={props.href}>
        <StyledButton
          disabled={props.disabled}
          href={props.fileUrl}
          width={props.width}
          bgColor={props.bgColor}
          type={props.type}
        >
          {props.content}
        </StyledButton>
      </Link>
    </>
  );
};

export default Button;
